<template>
	<div class="flex flex-row flex-wrap justify-start items-start w-screen sm:w-auto h-full px-4 py-6 sm:px-6 sm:py-12">
		<!-- Назад -->
		<router-link :to="{ name: 'ControlEvent' }" class="flex sm:hidden justify-center items-center border-2 border-blue-500 text-blue-500 font-semibold p-2 pl-3 rounded-xl mb-4">
			<svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6 1L1.70711 5.29289C1.31658 5.68342 1.31658 6.31658 1.70711 6.70711L6 11" stroke="#3377FF" stroke-width="2" stroke-linecap="round" />
			</svg>
			<p class="pl-3">Назад</p>
		</router-link>

		<!-- Заголовок -->
		<h1 class="flex w-full text-xl mb-4 sm:mb-7 font-extrabold sm:text-2xl">
			{{ nameMO }}
		</h1>

		<div class="flex flex-col w-full h-auto mt-4 rounded-lg bg-white filter shadow-default relative">
			<div class="tbody sm:hidden content-center sm:hidden">
				<!-- 1.1 (c) -->
				<div class="trow sm:px-8 w-full sm:border-b item-center sm:border-gray-100 text-gray-500">
					<div class="sm:p-0 w-full sm:w-3/12 flex justify-between items-center border-b border-gray-100 sm:border-0">
						<span class="table-cell">
							<span class="text-gray-400 font-normal select-none"> 1.1 (с) </span>
							<span
								class="ml-2 select-none cursor-pointer"
								@click="
									commentsFlag = !commentsFlag;
									commentsVisible = 1;
								"
							>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#F2F5FF" />
									<path
										d="M8.64489 12.0071V11.8778C8.6482 11.2779 8.70455 10.8007 8.81392 10.446C8.92661 10.0914 9.0857 9.80634 9.29119 9.59091C9.49669 9.37216 9.74692 9.17164
										10.0419 8.98935C10.2441 8.8634 10.4247 8.72585 10.5838 8.5767C10.7462 8.42424 10.8738 8.25521 10.9666 8.0696C11.0594 7.88068 11.1058 7.67022 11.1058
										7.43821C11.1058 7.17637 11.0445 6.94934 10.9219 6.7571C10.7992 6.56487 10.6335 6.41572 10.4247 6.30966C10.2192 6.2036 9.98887 6.15057 9.73366 6.15057C9.49834
										6.15057 9.27462 6.20194 9.0625 6.30469C8.85369 6.40412 8.67969 6.55658 8.54048 6.76207C8.40459 6.96425 8.32836 7.22112 8.31179 7.53267H6.5419C6.55848 6.90294
										6.71094 6.37595 6.99929 5.9517C7.29096 5.52746 7.67543 5.20928 8.1527 4.99716C8.63329 4.78504 9.16359 4.67898 9.74361 4.67898C10.3767 4.67898 10.9335 4.79001
										1.4141 5.01207C11.898 5.23414 12.2741 5.55066 12.5426 5.96165C12.8144 6.36932 12.9503 6.85322 12.9503 7.41335C12.9503 7.79119 12.889 8.12926 12.7663
										8.42756C12.647 8.72585 12.4763 8.991 12.2543 9.22301C12.0322 9.45502 11.7687 9.66217 11.4638 9.84446C11.1953 10.0102 10.9749 10.1825 10.8026 10.3615C10.6335
										10.5405 10.5076 10.7509 10.4247 10.9929C10.3452 11.2315 10.3037 11.5265 10.3004 11.8778V12.0071H8.64489ZM9.50994 15.1094C9.21165 15.1094 8.95478 15.0033
										8.73935 14.7912C8.52391 14.5791 8.41619 14.3205 8.41619 14.0156C8.41619 13.7173 8.52391 13.4621 8.73935 13.25C8.95478 13.0379 9.21165 12.9318 9.50994
										12.9318C9.80492 12.9318 10.0601 13.0379 10.2756 13.25C10.4943 13.4621 10.6037 13.7173 10.6037 14.0156C10.6037 14.2178 10.5523 14.4018 10.4496 14.5675C10.3501
										14.7332 10.2176 14.8658 10.0518 14.9652C9.88944 15.0613 9.70881 15.1094 9.50994 15.1094Z"
										fill="#A8B1CE"
									/>
								</svg>
							</span>
						</span>

						<p v-if="commentsFlag && commentsVisible === 1" class="flex absolute left-1/3 w-64 px-5 py-2 bg-blue-500 text-sm font-medium text-white rounded select-none">
							<span v-show="commentsVisible === 1">
								1.1 (с) Соответствие информации о деятельности медицинской организации, размещенной на общедоступных информационных ресурсах, перечню информации и требованиям к ней,
								установленными нормативными правовыми актами. На официальном сайте МО в информационно-телекоммуникационной сети "Интернет"
							</span>
						</p>

						<p class="block text-left w-10">
							<router-link
								title="Заполнить"
								v-if="!isCompletedQuestions_1_1_2 && (completedQuestions_1_1_2 === 0 || !completedQuestions_1_1_2)"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 2 }, query: { edite: true } }"
							>
								<btnAdd />
							</router-link>
							<router-link
								title="Дозаполнить"
								v-if="completedQuestions_1_1_2 > 0 && !isCompletedQuestions_1_1_2"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 2 }, query: { edite: true } }"
							>
								<btnEdite />
							</router-link>
							<router-link
								title="Просмотр блока"
								v-if="isCompletedQuestions_1_1_2"
								class="underline text-blue-500 cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 2 } }"
							>
								{{ res_1_1_2 }}
							</router-link>
						</p>
					</div>
				</div>

				<!-- 1.1 (п) -->
				<div class="trow sm:px-8 w-full sm:border-b item-center sm:border-gray-100 text-gray-500">
					<div class="sm:p-0 w-full sm:w-3/12 flex justify-between items-center border-b border-gray-100 sm:border-0">
						<span class="table-cell">
							<span class="text-gray-400 font-normal select-none"> 1.1 (п) </span>
							<span
								class="ml-2 select-none cursor-pointer"
								@click="
									commentsFlag = !commentsFlag;
									commentsVisible = 2;
								"
							>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#F2F5FF" />
									<path
										d="M8.64489 12.0071V11.8778C8.6482 11.2779 8.70455 10.8007 8.81392 10.446C8.92661 10.0914 9.0857 9.80634 9.29119 9.59091C9.49669 9.37216 9.74692 9.17164
										10.0419 8.98935C10.2441 8.8634 10.4247 8.72585 10.5838 8.5767C10.7462 8.42424 10.8738 8.25521 10.9666 8.0696C11.0594 7.88068 11.1058 7.67022 11.1058
										7.43821C11.1058 7.17637 11.0445 6.94934 10.9219 6.7571C10.7992 6.56487 10.6335 6.41572 10.4247 6.30966C10.2192 6.2036 9.98887 6.15057 9.73366 6.15057C9.49834
										6.15057 9.27462 6.20194 9.0625 6.30469C8.85369 6.40412 8.67969 6.55658 8.54048 6.76207C8.40459 6.96425 8.32836 7.22112 8.31179 7.53267H6.5419C6.55848 6.90294
										6.71094 6.37595 6.99929 5.9517C7.29096 5.52746 7.67543 5.20928 8.1527 4.99716C8.63329 4.78504 9.16359 4.67898 9.74361 4.67898C10.3767 4.67898 10.9335 4.79001
										1.4141 5.01207C11.898 5.23414 12.2741 5.55066 12.5426 5.96165C12.8144 6.36932 12.9503 6.85322 12.9503 7.41335C12.9503 7.79119 12.889 8.12926 12.7663
										8.42756C12.647 8.72585 12.4763 8.991 12.2543 9.22301C12.0322 9.45502 11.7687 9.66217 11.4638 9.84446C11.1953 10.0102 10.9749 10.1825 10.8026 10.3615C10.6335
										10.5405 10.5076 10.7509 10.4247 10.9929C10.3452 11.2315 10.3037 11.5265 10.3004 11.8778V12.0071H8.64489ZM9.50994 15.1094C9.21165 15.1094 8.95478 15.0033
										8.73935 14.7912C8.52391 14.5791 8.41619 14.3205 8.41619 14.0156C8.41619 13.7173 8.52391 13.4621 8.73935 13.25C8.95478 13.0379 9.21165 12.9318 9.50994
										12.9318C9.80492 12.9318 10.0601 13.0379 10.2756 13.25C10.4943 13.4621 10.6037 13.7173 10.6037 14.0156C10.6037 14.2178 10.5523 14.4018 10.4496 14.5675C10.3501
										14.7332 10.2176 14.8658 10.0518 14.9652C9.88944 15.0613 9.70881 15.1094 9.50994 15.1094Z"
										fill="#A8B1CE"
									/>
								</svg>
							</span>
						</span>

						<p v-if="commentsFlag && commentsVisible === 2" class="flex absolute left-1/3 w-64 px-5 py-2 bg-blue-500 text-sm font-medium text-white rounded select-none">
							<span>
								1.1 (п) - Соответствие информации о деятельности медицинской организации, размещенной на общедоступных информационных ресурсах, перечню информации и требованиям к ней,
								установленными нормативными правовыми актами. На информационных стендах в помещениях МО
							</span>
						</p>

						<p class="block text-left w-10">
							<router-link
								title="Заполнить"
								v-if="!isCompletedQuestions_1_1_1 && (completedQuestions_1_1_1 === 0 || !completedQuestions_1_1_1)"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 1 }, query: { edite: true } }"
							>
								<btnAdd />
							</router-link>
							<router-link
								title="Дозаполнить"
								v-if="completedQuestions_1_1_1 > 0 && !isCompletedQuestions_1_1_1"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 1 }, query: { edite: true } }"
							>
								<btnEdite />
							</router-link>
							<router-link
								title="Просмотр блока"
								v-if="isCompletedQuestions_1_1_1"
								class="underline text-blue-500 cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 1 } }"
							>
								{{ res_1_1_1 }}
							</router-link>
						</p>
					</div>
				</div>

				<!-- 1.2 -->
				<div class="trow sm:px-8 w-full sm:border-b item-center sm:border-gray-100 text-gray-500">
					<div class="sm:p-0 w-full sm:w-3/12 flex justify-between items-center border-b border-gray-100 sm:border-0">
						<span class="table-cell">
							<span class="text-gray-400 font-normal select-none"> 1.2 </span>
							<span
								class="ml-2 select-none cursor-pointer"
								@click="
									commentsFlag = !commentsFlag;
									commentsVisible = 3;
								"
							>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#F2F5FF" />
									<path
										d="M8.64489 12.0071V11.8778C8.6482 11.2779 8.70455 10.8007 8.81392 10.446C8.92661 10.0914 9.0857 9.80634 9.29119 9.59091C9.49669 9.37216 9.74692 9.17164
										10.0419 8.98935C10.2441 8.8634 10.4247 8.72585 10.5838 8.5767C10.7462 8.42424 10.8738 8.25521 10.9666 8.0696C11.0594 7.88068 11.1058 7.67022 11.1058
										7.43821C11.1058 7.17637 11.0445 6.94934 10.9219 6.7571C10.7992 6.56487 10.6335 6.41572 10.4247 6.30966C10.2192 6.2036 9.98887 6.15057 9.73366 6.15057C9.49834
										6.15057 9.27462 6.20194 9.0625 6.30469C8.85369 6.40412 8.67969 6.55658 8.54048 6.76207C8.40459 6.96425 8.32836 7.22112 8.31179 7.53267H6.5419C6.55848 6.90294
										6.71094 6.37595 6.99929 5.9517C7.29096 5.52746 7.67543 5.20928 8.1527 4.99716C8.63329 4.78504 9.16359 4.67898 9.74361 4.67898C10.3767 4.67898 10.9335 4.79001
										1.4141 5.01207C11.898 5.23414 12.2741 5.55066 12.5426 5.96165C12.8144 6.36932 12.9503 6.85322 12.9503 7.41335C12.9503 7.79119 12.889 8.12926 12.7663
										8.42756C12.647 8.72585 12.4763 8.991 12.2543 9.22301C12.0322 9.45502 11.7687 9.66217 11.4638 9.84446C11.1953 10.0102 10.9749 10.1825 10.8026 10.3615C10.6335
										10.5405 10.5076 10.7509 10.4247 10.9929C10.3452 11.2315 10.3037 11.5265 10.3004 11.8778V12.0071H8.64489ZM9.50994 15.1094C9.21165 15.1094 8.95478 15.0033
										8.73935 14.7912C8.52391 14.5791 8.41619 14.3205 8.41619 14.0156C8.41619 13.7173 8.52391 13.4621 8.73935 13.25C8.95478 13.0379 9.21165 12.9318 9.50994
										12.9318C9.80492 12.9318 10.0601 13.0379 10.2756 13.25C10.4943 13.4621 10.6037 13.7173 10.6037 14.0156C10.6037 14.2178 10.5523 14.4018 10.4496 14.5675C10.3501
										14.7332 10.2176 14.8658 10.0518 14.9652C9.88944 15.0613 9.70881 15.1094 9.50994 15.1094Z"
										fill="#A8B1CE"
									/>
								</svg>
							</span>
						</span>

						<p v-if="commentsFlag && commentsVisible === 3" class="flex absolute left-1/3 w-64 px-5 py-2 bg-blue-500 text-sm font-medium text-white rounded select-none">
							<span>
								1.2 - Обеспечение на официальном сайте медицинской организации наличия и функционирования дистанционных способов взаимодействия с получателями услуг (наличие на
								официальном сайте медицинской организации)
							</span>
						</p>

						<p class="block text-left w-10">
							<router-link
								title="Заполнить"
								v-if="!isCompletedQuestions_1_2 && (completedQuestions_1_2 === 0 || !completedQuestions_1_2)"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 3 }, query: { edite: true } }"
							>
								<btnAdd />
							</router-link>
							<router-link
								title="Дозаполнить"
								v-if="completedQuestions_1_2 > 0 && !isCompletedQuestions_1_2"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 3 }, query: { edite: true } }"
							>
								<btnEdite />
							</router-link>
							<router-link
								title="Просмотр блока"
								v-if="isCompletedQuestions_1_2"
								class="underline text-blue-500 cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 3 } }"
							>
								{{ res_1_2 }}
							</router-link>
						</p>
					</div>
				</div>

				<!-- 3.1 -->
				<div class="trow sm:px-8 w-full sm:border-b item-center sm:border-gray-100 text-gray-500">
					<div class="sm:p-0 w-full sm:w-3/12 flex justify-between items-center border-b border-gray-100 sm:border-0">
						<span class="table-cell">
							<span class="text-gray-400 font-normal select-none"> 3.1 </span>
							<span
								class="ml-2 select-none cursor-pointer"
								@click="
									commentsFlag = !commentsFlag;
									commentsVisible = 4;
								"
							>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#F2F5FF" />
									<path
										d="M8.64489 12.0071V11.8778C8.6482 11.2779 8.70455 10.8007 8.81392 10.446C8.92661 10.0914 9.0857 9.80634 9.29119 9.59091C9.49669 9.37216 9.74692 9.17164
										10.0419 8.98935C10.2441 8.8634 10.4247 8.72585 10.5838 8.5767C10.7462 8.42424 10.8738 8.25521 10.9666 8.0696C11.0594 7.88068 11.1058 7.67022 11.1058
										7.43821C11.1058 7.17637 11.0445 6.94934 10.9219 6.7571C10.7992 6.56487 10.6335 6.41572 10.4247 6.30966C10.2192 6.2036 9.98887 6.15057 9.73366 6.15057C9.49834
										6.15057 9.27462 6.20194 9.0625 6.30469C8.85369 6.40412 8.67969 6.55658 8.54048 6.76207C8.40459 6.96425 8.32836 7.22112 8.31179 7.53267H6.5419C6.55848 6.90294
										6.71094 6.37595 6.99929 5.9517C7.29096 5.52746 7.67543 5.20928 8.1527 4.99716C8.63329 4.78504 9.16359 4.67898 9.74361 4.67898C10.3767 4.67898 10.9335 4.79001
										1.4141 5.01207C11.898 5.23414 12.2741 5.55066 12.5426 5.96165C12.8144 6.36932 12.9503 6.85322 12.9503 7.41335C12.9503 7.79119 12.889 8.12926 12.7663
										8.42756C12.647 8.72585 12.4763 8.991 12.2543 9.22301C12.0322 9.45502 11.7687 9.66217 11.4638 9.84446C11.1953 10.0102 10.9749 10.1825 10.8026 10.3615C10.6335
										10.5405 10.5076 10.7509 10.4247 10.9929C10.3452 11.2315 10.3037 11.5265 10.3004 11.8778V12.0071H8.64489ZM9.50994 15.1094C9.21165 15.1094 8.95478 15.0033
										8.73935 14.7912C8.52391 14.5791 8.41619 14.3205 8.41619 14.0156C8.41619 13.7173 8.52391 13.4621 8.73935 13.25C8.95478 13.0379 9.21165 12.9318 9.50994
										12.9318C9.80492 12.9318 10.0601 13.0379 10.2756 13.25C10.4943 13.4621 10.6037 13.7173 10.6037 14.0156C10.6037 14.2178 10.5523 14.4018 10.4496 14.5675C10.3501
										14.7332 10.2176 14.8658 10.0518 14.9652C9.88944 15.0613 9.70881 15.1094 9.50994 15.1094Z"
										fill="#A8B1CE"
									/>
								</svg>
							</span>
						</span>

						<p v-if="commentsFlag && commentsVisible === 4" class="flex absolute left-1/3 w-64 px-5 py-2 bg-blue-500 text-sm font-medium text-white rounded select-none">
							<span> 3.1 - Оборудование территории, прилегающей к медицинской организации, и ее помещений с учетом доступности для инвалидов </span>
						</p>

						<p class="block text-left w-10">
							<router-link
								title="Заполнить"
								v-if="!isCompletedQuestions_3_1 && (completedQuestions_3_1 === 0 || !completedQuestions_3_1)"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 4 }, query: { edite: true } }"
							>
								<btnAdd />
							</router-link>
							<router-link
								title="Дозаполнить"
								v-if="completedQuestions_3_1 > 0 && !isCompletedQuestions_3_1"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 4 }, query: { edite: true } }"
							>
								<btnEdite />
							</router-link>
							<router-link
								title="Просмотр блока"
								v-if="isCompletedQuestions_3_1"
								class="underline text-blue-500 cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 4 } }"
							>
								{{ res_3_1 }}
							</router-link>
						</p>
					</div>
				</div>

				<!-- 3.2 -->
				<div class="trow sm:px-8 w-full sm:border-b item-center sm:border-gray-100 text-gray-500">
					<div class="sm:p-0 w-full sm:w-3/12 flex justify-between items-center border-b border-gray-100 sm:border-0">
						<span class="table-cell">
							<span class="text-gray-400 font-normal select-none"> 3.2 </span>
							<span
								class="ml-2 select-none cursor-pointer"
								@click="
									commentsFlag = !commentsFlag;
									commentsVisible = 5;
								"
							>
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<circle cx="10" cy="10" r="10" fill="#F2F5FF" />
									<path
										d="M8.64489 12.0071V11.8778C8.6482 11.2779 8.70455 10.8007 8.81392 10.446C8.92661 10.0914 9.0857 9.80634 9.29119 9.59091C9.49669 9.37216 9.74692 9.17164
										10.0419 8.98935C10.2441 8.8634 10.4247 8.72585 10.5838 8.5767C10.7462 8.42424 10.8738 8.25521 10.9666 8.0696C11.0594 7.88068 11.1058 7.67022 11.1058
										7.43821C11.1058 7.17637 11.0445 6.94934 10.9219 6.7571C10.7992 6.56487 10.6335 6.41572 10.4247 6.30966C10.2192 6.2036 9.98887 6.15057 9.73366 6.15057C9.49834
										6.15057 9.27462 6.20194 9.0625 6.30469C8.85369 6.40412 8.67969 6.55658 8.54048 6.76207C8.40459 6.96425 8.32836 7.22112 8.31179 7.53267H6.5419C6.55848 6.90294
										6.71094 6.37595 6.99929 5.9517C7.29096 5.52746 7.67543 5.20928 8.1527 4.99716C8.63329 4.78504 9.16359 4.67898 9.74361 4.67898C10.3767 4.67898 10.9335 4.79001
										1.4141 5.01207C11.898 5.23414 12.2741 5.55066 12.5426 5.96165C12.8144 6.36932 12.9503 6.85322 12.9503 7.41335C12.9503 7.79119 12.889 8.12926 12.7663
										8.42756C12.647 8.72585 12.4763 8.991 12.2543 9.22301C12.0322 9.45502 11.7687 9.66217 11.4638 9.84446C11.1953 10.0102 10.9749 10.1825 10.8026 10.3615C10.6335
										10.5405 10.5076 10.7509 10.4247 10.9929C10.3452 11.2315 10.3037 11.5265 10.3004 11.8778V12.0071H8.64489ZM9.50994 15.1094C9.21165 15.1094 8.95478 15.0033
										8.73935 14.7912C8.52391 14.5791 8.41619 14.3205 8.41619 14.0156C8.41619 13.7173 8.52391 13.4621 8.73935 13.25C8.95478 13.0379 9.21165 12.9318 9.50994
										12.9318C9.80492 12.9318 10.0601 13.0379 10.2756 13.25C10.4943 13.4621 10.6037 13.7173 10.6037 14.0156C10.6037 14.2178 10.5523 14.4018 10.4496 14.5675C10.3501
										14.7332 10.2176 14.8658 10.0518 14.9652C9.88944 15.0613 9.70881 15.1094 9.50994 15.1094Z"
										fill="#A8B1CE"
									/>
								</svg>
							</span>
						</span>

						<p v-if="commentsFlag && commentsVisible === 5" class="flex absolute left-1/3 w-64 px-5 py-2 bg-blue-500 text-sm font-medium text-white rounded select-none">
							<span> 3.2 - Обеспечение в медицинской организации условий доступности, позволяющих инвалидам получать услуги наравне с другими </span>
						</p>

						<p class="block text-left w-10">
							<router-link
								title="Заполнить"
								v-if="!isCompletedQuestions_3_2 && (completedQuestions_3_2 === 0 || !completedQuestions_3_2)"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 5 }, query: { edite: true } }"
							>
								<btnAdd />
							</router-link>
							<router-link
								title="Дозаполнить"
								v-if="completedQuestions_3_2 > 0 && !isCompletedQuestions_3_2"
								class="cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 5 }, query: { edite: true } }"
							>
								<btnEdite />
							</router-link>
							<router-link
								title="Просмотр блока"
								v-if="isCompletedQuestions_3_2"
								class="underline text-blue-500 cursor-pointer"
								:to="{ name: 'Form', params: { anketaResultId: idMo, order: 5 } }"
							>
								{{ res_3_2 }}
							</router-link>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import btnAdd from '../../../../assets/img/CEBtnAdd.svg';
import btnEdite from '../../../../assets/img/CEBtnEdit.svg';
import { getters } from '@/store/store';

export default {
	components: {
		btnAdd,
		btnEdite
	},
	data: () => ({
		commentsFlag: false,
		commentsVisible: null,
		nameMO: '',
		idMo: '',
		completedQuestions_1_1_1: null,
		completedQuestions_1_1_2: null,
		completedQuestions_1_2: null,
		completedQuestions_3_1: null,
		completedQuestions_3_2: null,
		isCompletedQuestions_1_1_1: null,
		isCompletedQuestions_1_1_2: null,
		isCompletedQuestions_1_2: null,
		isCompletedQuestions_3_1: null,
		isCompletedQuestions_3_2: null,
		res_1_1_1: null,
		res_1_1_2: null,
		res_1_2: null,
		res_3_1: null,
		res_3_2: null
	}),
	computed: {
		...getters
	},
	methods: {
		getMO(id) {
			this.$http
				.post(`OS/GetControlUserStat?userId=${getters.getAuthUser().id}`, {
					filters: [
						{
							fieldName: 'Id',
							filterType: 1,
							filterValueType: 1,
							value1: id,
							value2: null
						}
					],
					sortOption: {
						fieldName: '',
						sortType: null
					},
					pageSize: 100,
					currentPage: 0
				})
				.then((res) => {
					if (res.status === 200) {
						this.completedQuestions_1_1_1 = res.data.data[0].completedQuestions_1_1_1;
						this.completedQuestions_1_1_2 = res.data.data[0].completedQuestions_1_1_2;
						this.completedQuestions_1_2 = res.data.data[0].completedQuestions_1_2;
						this.completedQuestions_3_1 = res.data.data[0].completedQuestions_3_1;
						this.completedQuestions_3_2 = res.data.data[0].completedQuestions_3_2;

						this.isCompletedQuestions_1_1_1 = res.data.data[0].isCompletedQuestions_1_1_1;
						this.isCompletedQuestions_1_1_2 = res.data.data[0].isCompletedQuestions_1_1_2;
						this.isCompletedQuestions_1_2 = res.data.data[0].isCompletedQuestions_1_2;
						this.isCompletedQuestions_3_1 = res.data.data[0].isCompletedQuestions_3_1;
						this.isCompletedQuestions_3_2 = res.data.data[0].isCompletedQuestions_3_2;

						this.res_1_1_1 = res.data.data[0].res_1_1_1;
						this.res_1_1_2 = res.data.data[0].res_1_1_2;
						this.res_1_2 = res.data.data[0].res_1_2;
						this.res_3_1 = res.data.data[0].res_3_1;
						this.res_3_2 = res.data.data[0].res_3_2;
					}
				});
		}
	},
	created() {
		if (this.$route.query.nameMO) this.nameMO = this.$route.query.nameMO;
		if (this.$route.query.idMo) this.idMo = this.$route.query.idMo;

		this.getMO(this.idMo);
	}
};
</script>
